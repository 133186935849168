import React from 'react';
import Popup from 'reactjs-popup';
import { BrowserRouter as  Link } from 'react-router-dom';
import { Navbar, Nav } from 'rsuite';

 class Welcome extends React.Component{
    constructor(props){
        super(props);
        this.setState={
          
        }
       this.route = this.route.bind(this);
    }
    route=(e)=>{
        this.props.history.push('/Routesheet');
      }
      missed=(e)=>{
        this.props.history.push('/missed-visit');
      }
      toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
      }
     render(){
         return(
             <div>
                 {/********************************************* navbar html ***************************************/}
                 <div class="wrapper">
                    <nav class="navbar navbar-expand-md navbar-dark" style={{height: "60px"}}>
                    
                    <div class="mx-auto justify-content-center" id="collapsingNavbar">
                        <ul class="navbar-nav">
                            <li class="">
                                <a class="text-white navbar-brand mr-0 pl-2 " href="" ></a>
                            </li>
                        </ul>
                    </div>
                </nav>
		    </div>
                    <div class="container-fluid text-left maxw">
                      {/* <!-- Top navigation --> */}
                  <header class="py-3 background text-dark font-weight-bold">
                      <div class="col text-center" style={{fontSize: "18px"}}><img src="images/logo.png" alt="" class="float-left" />Gould Therapy, LLC</div>
                </header>
                <br>
                </br>
                
                 {/********************************************* Buttons Html **************************************/}
                 <div className="container text-center mt-5">
                  <p>
                    <button type="button" className="btn rounded  font-weight-bold text-dark"
                     onClick={this.route.bind(this)}>
                      1. Route Sheet</button></p>
                  <p>	
                    <button type="button" className="btn rounded  font-weight-bold text-dark"
                     onClick={this.missed.bind(this)}>
                        2. Missed visit</button></p>
                  <p>	
                    <button type="button" className="btn rounded  font-weight-bold text-dark">3. Notes</button></p>
                </div>
              </div>
             </div>
         )
     }                 
}
export default Welcome;