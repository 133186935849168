import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import ReactDOM from 'react-dom';
import {Prompt} from "react-router-dom"

function App(){
  const [imgURL, setImageURL] = useState(null);
  const sigCanvas = useRef({})


 //var base64Icon = 'data:image/png;base64 ,'+ imgURL;
  
  const clear= () => sigCanvas.current.clear(); 
  
  
  const save = () =>{
    
  setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
  
  
   }

 const  onClose= () => {
     sigCanvas.current.close();
   }


  

  // const enc = window.btoa(imgURL);
  // const byteCharacters = atob(enc);
  // const byteNumbers = new Array(byteCharacters.length);
  //   for (let i = 0; i < byteCharacters.length; i++) {
  //   byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }
  //   const byteArray = new Uint8Array(byteNumbers);
  //   const blob = new Blob([byteArray], {type: 'image/png'});



  localStorage.setItem("pt_sign",imgURL);
console.log("imageCanvas"+imgURL);
//ReactDOM.render(<Example data={imgURL} />, document.getElementById('App'))
   return (
     <div className="App" id="myModal">
       {/* <Prompt when={true} message={"Are you sure you want to leave this page?"}/> */}
       <Popup 
       id="myModal"
         modal 
        trigger={<i className="icon-sign fa fa-edit float-left " style={{fontSize:"30px"}} />}
        closeOnDocumentClick={false}
        >
          {close => (
            <>
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
              className: "signatureCanvas"
              }}
              />
              <button onClick={save} data-dismiss="modal">Save</button>
              <button onClick={clear}>Clear</button>
              <button onClick={close} data-dismiss="modal">Close</button>
              </>
          )}
        </Popup>
        <br />
        <br />
        {imgURL ? (
          <img
            src={`${imgURL}`}
             alt="my signature"
             style={{
              display: "block",
              margin: "0 auto",
              border: "1px solid black",
              width: "150px",
              height: "150px",
              crop: "scale",
              overflowY: 'hidden'
            }}
            />
        ) : null}
     </div>
   )
}
export default App;