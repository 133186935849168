import React, { Component } from 'react';
import qs from 'qs';
import Popup from "reactjs-popup"
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Table,Button } from 'react-bootstrap';  


class HHAname extends Component{
    constructor(props)
    {
      super(props);
      this.state={
       hha_name:'',
       HHAData: [],
       modal: false,
       name: "",
       modalInputName: "",
       Therapist_name:'',
       userData:'',
       new_hha: ''
      }
     
      this.handleSubmit = this.handleSubmit.bind(this);
    };
    componentDidMount() {         
      fetch("https://api.gould-pt.com/api/email/HHAList",{
        
      })
      .then(res=>res.json())
      .then(json=>{
        this.setState({
         HHAData:json,
        })
        console.log(json);
      })
       
        // console.log("data="+HHAname);
    }  

    handleSubmit=(e)=>{
      debugger;
      if(this.refs.hha_name.value=== ""){
           alert("Enter hhaname")
          
      }
      else {
        

      
      fetch('https://api.gould-pt.com/api/Email/InsertHHA' ,
      
        {  
            method: 'POST',
            mode:'no-cors',
            headers: {
    
            'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
            
            
          },
          body:qs.stringify({
            hha_name :this.refs.hha_name.value,
           }) 
       
        })
        .then(response => {
          return alert("Successfully Sent")
        })
        .catch(error => {
          return alert("Successfully Sent")
          
        })  
       //alert("hello")
      window.location.reload();
    

  }
 
    }
    
     handleDelete=(hha_name)=>{
       
        confirmAlert({
          title: 'Delete HHAname',
          message: localStorage.getItem("deleteContact"),    
          buttons: [  
            { 
              label: 'Yes',
               onClick: () => { 
                var Cid=localStorage.getItem("contactID")
                const name = localStorage.getItem('userEmail')
                // var Url = 'https://pwaapi.heyyouwhatsup.com/api/PWA/DeleteContact?currentUserEmail='+name + "&" + "aContactId=" +Cid;
                debugger;
                //alert('https://api.gould-pt.com/api/Email/DeleteHHA?HHAname='+hha_name);
                fetch('https://api.gould-pt.com/api/Email/DeleteHHA?HHAname='+hha_name,
              {
                method:"Post"       
              })
              //  debugger;
              // alert(Url);
              window.location.reload();
            }
          },
          
         
            {
              label: 'No',
              
            }
            
          ]
        })
        //window.location.reload();
      }

      toggleModal(hha_name) {  
        localStorage.setItem('hha',hha_name);
        //alert('https://api.gould-pt.com/api/Email/HHANameDetail?name='+this.refs.new_hha);
        
        fetch('https://localhost:44398/api/Email/UpdateHHA',
          {  
            method: 'POST',
            mode:'no-cors',
            headers: {
    
            'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
            
            
          },
          body:qs.stringify({
            oldhha: localStorage.getItem('hha'),
            hha_name :this.state.new_hha.value,
           }) 
       
        })
        .then(response => {
          return ("Successful")
        })
        .catch(error => {
          return   ("Try again");
          
        })  
       //alert("hello")
      window.location.reload();         
   }  
      
   render(){
    var {HHAData}= this.state;
    

      return(
          <div>
              <div class="wrapper">
                  <nav class="navbar navbar-expand-md navbar-dark">
                    
                    <div class="mx-auto justify-content-center" id="collapsingNavbar">
                        <ul class="navbar-nav">
                            <li class="">
                                <a class="text-white navbar-brand mr-0 pl-2 " href="" >HHA Form</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                    </div>
                    
                  <div className="container-fluid text-left maxw">
                    {/* <!-- Top navigation --> */}
                <header className="py-3 background text-dark font-weight-bold">
                    <div className="col text-center" style={{fontSize: "18px"}}>
                      <img src="images/logo.png" alt="" className="float-left" />Gould Therapy, LLC</div>
              </header>
{/********************************************** Form ***********************************************/} 
              <div style={{marginTop: "60px"}}> 
              <label htmlFor="name">HHA name</label> 
                    <div className="input-group mb-3">
                      <input type="text" className="form-control border-right-1" 
                         placeholder="HHA Name" 
                         ref="hha_name"
                          name 
                      />
                    
                    <button type="submit" onClick={this.handleSubmit.bind(this)}  className="btn btn-danger">Add</button>
                                             
                    </div>
                    <div>  
                    <br></br><br></br>
                    <table className="table table-striped" style={{ marginTop: 10 }}  >  
                      <thead>  
                        <tr>                                
                          <th>Name</th>                            
                          <th colSpan="4">Action</th>

                        </tr>  
                      </thead>  
                      {HHAData.map(userContact=>(
                      <tbody >  
                      {/* { this.tabRow() }    */}
                      
                     <td data-title={userContact.hha_name} >
                       {userContact.hha_name}
                       
                     </td>
                     
                      <td>  
                        {/* <Modal onClick={() => {this.toggleModal(userContact.hha_name) }} /> */}
                      {/* <button type="button"    className="btn btn-info">Edit</button>   */}

{/*.................................................... HHA MODAL...................................................... */}

                        <div className="App" id="myModal">
                              <Popup 
                              id="myModal"
                                modal 
                                trigger={<button type="button" className="btn btn-danger">Edit</button>}
                                closeOnDocumentClick={false}
                                >
                                  {close => (
                                    <>
                                    <div style={{marginTop: "20px", width: '350px', marginLeft: '20px' , marginRight: '20px', marginBottom: '20px'}}> 
                                     <div className="input-group mb-6">
                                      <input type="text" className="form-control border-right-1" 
                                        placeholder="HHA Name" 
                                        ref="new_hha"
                                       name="new_hha"
                                      
                                      
                                          
                                      />
                                      </div>
                                      </div>
                                  <div style={{marginRight: '20px'}}>
                                      <button className="btn btn-info" onClick={() => {this.toggleModal(userContact.hha_name) }} data-dismiss="modal">Save</button>&emsp;
                                     
                                      <button onClick={close} className="btn btn-danger" data-dismiss="modal">Close</button>
                                      </div>
                                      </>
                                  )}
                                </Popup>
                                <br />
                                <br />
                                
                            </div>
                          



  {/*...........................................HHA MODAL ENDS.............................................  */}
                      </td>
                      <td> 
                        <button type="button"  onClick={() => {this.handleDelete(userContact.hha_name) }}  className="btn btn-danger">Delete</button>  
                      </td>     
                      </tbody> 
                      ))} 
                      {/* {HHA_Name.map(hha=>( */}
                      

                    </table>  
                
                  </div>  
                    

              </div>
              </div>
          </div>
      )
  }
}

export default HHAname;