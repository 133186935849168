import React from 'react';
import logo from './logo.svg';
import './App.css';
import Welcome from './Components/WelcomePage';
import RouteComponent from './Components/Route';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import sign from './Components/Patient_signcanvas';
import NetworkDetector from './Hoc/NetworkDetector';
import MissedVisit from './Components/Missed_Visit';
import HHAname from './Components/HHA_name';
import HHa from './Components/HHA_Table';


function App() {
  return (
    <div className="App">
      <Router> 
          <Route exact path="/" render={() => (
                    <Redirect to="/" />
                )} />
      <Switch>
        <Route exact path="/" component={Welcome} ></Route>
        <Route exact path="/Routesheet" component={RouteComponent}></Route>
        <Route exact path="/sign" component={sign}></Route> 
        <Route exact path="/missed-visit" component={MissedVisit}></Route>
        <Route exact path="/hha-form" component={HHAname}></Route>
        <Route exact path="/hha" component={HHa}></Route>
      </Switch>
    </Router>

    </div>
  );
}

export default NetworkDetector(App);

