import React, {useState, useRef} from "react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import ReactDOM from 'react-dom';
import {Prompt} from "react-router-dom";

var fs = require('fs');

function App(){
  const [imgURL, setImageURL] = useState(null);
  const sigCanvas = useRef({})
  
 //var base64Icon = 'data:image/png;base64 ,'+ imgURL;
  
  const clear= () => sigCanvas.current.clear(); 
  const save = () =>{
   
  setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
  
  }

//   var img = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0"
//     + "NAAAAKElEQVQ4jWNgYGD4Twzu6FhFFGYYNXDUwGFpIAk2E4dHDRw1cDgaCAASFOffhEIO"
//     + "3gAAAABJRU5ErkJggg==";
// // strip off the data: url prefix to get just the base64-encoded bytes
// var data = img.replace(/^data:image\/\w+;base64,/, "");
// var buf = new Buffer(data, 'base64');
// fs.writeFile('image.png', buf);
  

localStorage.setItem("sign",imgURL);
console.log("imageCanvas"+imgURL);
//ReactDOM.render(<Example data={imgURL} />, document.getElementById('App'))
   return (
     <div className="App" >
       {/* <Prompt when={true} message={"Are you sure you want to leave this page?"}/> */}
       <Popup 
      
         modal 
        trigger={<i className="icon-sign fa fa-edit float-left " style={{fontSize:"30px"}}/>}
        closeOnDocumentClick={false}
        >
          {close => (
            <>
            <SignaturePad
         
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas"
              }}
              />
              <button onClick={save} data-dismiss="modal">Save</button>
              <button onClick={clear} data-dismiss="modal">Clear</button>
              <button onClick={close} data-dismiss="modal">Close</button>
              </>
          )}
        </Popup>
        <br />
        <br />
        {imgURL ? (
          <img
            src={`${imgURL}`}
             alt="my signature"
             style={{
              display: "block",
              margin: "0 auto",
              border: "1px solid black",
              width: "150px",
              height: "150px",
              overflowY: 'hidden'
              
            }}
            />
        ) : null}
       
        
     </div>
   )
}
export default App;