import React, { useRef, useState, state, Component } from 'react';
import { Navbar, Nav, Button } from 'rsuite';
import { Prompt } from 'react-router'
import 'font-awesome/css/font-awesome.min.css';
import Signcanvas from './Patient_signcanvas';
import SignatureCanvs from './Pta_signcanvas';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import qs from 'qs';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Search from 'react-search';


console.log("storedsign="+localStorage.getItem("sign"))
class Missedvisit extends Component{
    constructor(props)
    {
      super(props);
      this.state={
        therapist_name:'',
          patient_name:'',
          plannedvisit: moment().toLocaleString(),
          medical_record: '',
          hha_name:'',
          pta_sign: localStorage.getItem("pt_sign"),
         
          HHAData: [],
          reason:"no comments",
          TherapistData: [],
          Therapist_name:'',
          Therapist:[]
      }
      this.handleChange = this.handleChange.bind(this);
      //this.handleSubmit = this.handleSubmit.bind(this);
      this.resetData = this.resetData.bind(this);
    };
   
    componentDidMount() {         
      fetch("https://api.gould-pt.com/api/email/HHAList",{
        
       })
       .then(res=>res.json())
       .then(json=>{
         this.setState({
          HHAData:json,
         })
         console.log(json);
       });
     // debugger;
       fetch("https://api.gould-pt.com/api/Email/Therapistlist",{
      })
      .then(res=>res.json())
      .then(json=>{
        this.setState({
          Therapist:json,
        })
        console.log(json);
      })
       
  }  
      
    handleChange(e) {
      let fields = this.state.fields;
       fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
   }

    resetData=()=>{
      this.refs.therapist_name.value="";
      this.refs.patient_name.value="";
      this.refs.plannedvisit.value="";
      this.refs.hha_name.value="";
      this.refs.reason.value="";
      this.refs.medical_record.value=""
    }
    
    handleSubmit=(e)=>{
      if(this.refs.therapist_name.value=== ""||this.refs.patient_name.value==="" || this.refs.plannedvisit.value==="" || this.refs.hha_name.value==="" ||
         this.refs.medical_record.value=== "" )
      {
           alert("Enter all the Inputs")
          
      }
      else {
        if(this.refs.reason.value===""){

          this.state.reason.value= "no comments" ;
        }
       
      fetch('https://api.gould-pt.com/api/Email/MissedVisit' ,
        {  
            method: 'POST',
            mode:'no-cors',
            headers: {
            
            
            'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
            
            
          },
          body:qs.stringify({

            therapist_name :this.refs.therapist_name.value,
            patient_name :this.refs.patient_name.value,
            pta_sign :localStorage.getItem("pt_sign"),
            plannedvisit :this.refs.plannedvisit.value,
            hha_name : this.refs.hha_name.value,
            medical_record : this.refs.medical_record.value,
            reason : this.refs.reason.value
            
         }) 
         
       
        })
        .then(response => {
          return alert("Successfully Sent")
        })
        .catch(error => {
          return alert("Please Try Again!!")
          
        })  
    
      // this.props.history.push('/home')
   
    localStorage.clear();
  }
    
    }

    render(){     
      var {HHAData}= this.state;
      var {Therapist} = this.state;
        return(              
          <div  >  
            
               {/********************************************* top navbar ******************************************/}
               <div class="wrapper">
                    <nav class="navbar navbar-expand-md navbar-dark">
                      
                      <div class="mx-auto justify-content-center" id="collapsingNavbar">
                          <ul class="navbar-nav">
                              <li class="">
                                  <a class="text-white navbar-brand mr-0 pl-2 " href="" >Missed Visit</a>
                              </li>
                          </ul>
                      </div>
                  </nav>
                      </div>
                    <div className="container-fluid text-left maxw">
                      {/* <!-- Top navigation --> */}
                  <header className="py-3 background text-dark font-weight-bold">
                      <div className="col text-center" style={{fontSize: "18px"}}>
                        <img src="images/logo.png" alt="" className="float-left" />Gould Therapy, LLC</div>
                </header>
               {/********************************************** Form ***********************************************/} 
                    <div style={{marginTop: "60px"}}>
                    <label htmlFor="name">Therapist name</label> 
                      <div className="input-group mb-3">
                       
                                <div className="input-group mb-3">
                                <select className="form-control border-right-0" name="TherapistName" 
                                value={this.state.id}  ref="therapist_name"  placeholder="TherapistName">  
                                  <option></option>  
                                      {this.state.Therapist.map((e, key) => {  
                                            return <option key={key} value={e.Therapist_name}>{e.Therapist_name}
                                            {console.log("Therapist_name="+ e.Therapist_name)}</option>;  
                                            
                                        })}  
                                       
                                </select>  
                                 
                                  <div className="input-group-append border-left-0">
                                    <span className="input-group-text bg-white"><i className="fa fa-user" /></span>
                                  </div>
                                </div>
                      </div>
                                     
                      <label htmlFor="name">Patient's name</label>		
                        <div className="input-group mb-3">
                          <input type="text" className="form-control border-right-0" 
                            placeholder="Patient Name" 
                            name 
                            ref="patient_name"
                          />
                          <div className="input-group-append border-left-0">
                            <span className="input-group-text bg-white"><i className="fa fa-wheelchair" /></span>
                          </div>
                        </div>

                        <label htmlFor="name">Medical Record Number</label>		
                        <div className="input-group mb-3">
                          <input type="text" className="form-control border-right-0" 
                            placeholder="" 
                            name 
                            ref="medical_record"
                          />
                          <div className="input-group-append border-left-0">
                            <span className="input-group-text bg-white"></span>
                          </div>
                        </div>

                        <label htmlFor="name">Date of planned visit</label>
                        <div className="input-group mb-3">
                          <input type="date" className="form-control border-right-0" 
                            id="datepicker" 
                            ref="plannedvisit"
                            placeholder="Date of Visit" 
                            name 
                            style={{display: "flow-root"}}
                            />                                
                          <div className="input-group-append border-left-0">
                            <span className="input-group-text bg-white"><i className="fa fa-calendar" /></span>
                          </div>
                        </div>

                        
                        <label htmlFor="name">HHA name</label>
                                <div className="input-group mb-3">
                                <select className="form-control border-right-0" name="country" 
                                value={this.state.Id}  ref="hha_name"  >  
                                  <option>Select HHA </option>  
                                      {this.state.HHAData.map((e, key) => {  
                                            return <option key={key} value={e.hha_name}>{e.hha_name}
                                            {console.log("hhadata="+ e.hha_name)}</option>;  
                                            
                                        })}  
                                       
                                </select>  
                                 
                                  <div className="input-group-append border-left-0">
                                    <span className="input-group-text bg-white"><i className="fa fa-user" /></span>
                                  </div>
                                </div>

                        <label htmlFor="name">Reason for missed visit </label>
                        <div className="input-container mb-3">
                          <textarea className="input-field custum" rows={3} defaultValue={""} ref="reason" />
                          <i className="icon fa fa-comment-o" />
                        </div>

                       
                        <label htmlFor="name">PT or PTA signature</label>
                        <SignatureCanvs 
                           onChange = {this.state.pta_sign}
                           value = {this.state.pta_sign}
                          />
                        
                        
                      
                      <div className="row text-center my-3">
                        <div className="col text-right">
                         <a href="#"> <input type="submit" onClick={this.handleSubmit.bind(this)} className="btn btn-info" name="Submit"></input></a>
                        </div>
                        <div className="col text-left">
                          <button type="submit" onClick={this.resetData.bind(this)}   className="btn btn-danger">Clear</button>
                        </div>
                      </div>
                      
                </div>
              </div>  
          </div>
        )
      }
    }
      
export default Missedvisit;