import React, { useRef, useState, state, Component } from 'react';
import { Navbar, Nav, Button } from 'rsuite';
import { Prompt } from 'react-router'
import 'font-awesome/css/font-awesome.min.css';
import Signcanvas from './Patient_signcanvas';
import SignatureCanvs from './Pta_signcanvas';
import moment from 'moment';
import styles from './sign.module.css'
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


console.log("storedsign="+localStorage.getItem("sign"))
console.log("Therapist Name="+localStorage.getItem("therapist"))


class RouteComponent extends Component{
    constructor(props)
    {
      super(props);
      this.state={
        therapist_name:'',
          patient_name:'',
          DOV: moment().toLocaleString(),
          SOV:'',
          EOV:'',
          patient_sign: localStorage.getItem("sign"),
          hha_name:'',
          pta_sign: localStorage.getItem("pt_sign"),
          signcanvas: '',
          cmnt:'',
          cmnts:"no comments"
          
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.resetData = this.resetData.bind(this);

      localStorage.setItem("patient",this.state.patient_name)
      console.log(localStorage.getItem("patient"))
    };
    // image = new Image();
    // componentDidUpdate = () => {
    //   if (shouldBlockNavigation) {
    //     window.onbeforeunload = () => true
    //   } else {
    //     window.onbeforeunload = undefined
    //   }
    // }

    
      
    handleChange(e) {
      let fields = this.state.fields;
       fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });
  
    }    
    resetData=()=>{
      this.refs.therapist_name.value="";
      this.refs.patient_name.value="";
      this.refs.DOV.value="";
      this.refs.SOV.value="";
      this.refs.EOV.value="";
      this.refs.signcanvas="";
      this.refs.hha_name.value="";
      this.refs.cmnt.value="";
    }
    
    handleSubmit=(e)=>{
      if(this.refs.therapist_name.value=== ""||this.refs.patient_name.value==="" || this.refs.DOV.value==="" || this.refs.hha_name.value==="")
      {
           alert("Enter all the Inputs")
          
      }
      else {
        if(this.refs.cmnt.value===""){

          this.state.cmnt= "no comments" ;
        }
        window.localStorage.setItem("therapist",this.refs.therapist_name.value);
        window.localStorage.setItem("patient",this.refs.patient_name.value);
        window.localStorage.setItem("DOV",this.refs.DOV.value);
        window.localStorage.setItem("SOV",this.refs.SOV.value);
        window.localStorage.setItem("hha",this.refs.hha_name.value);
        window.localStorage.setItem("EOV",this.refs.EOV.value);
        window.localStorage.setItem("cmnts",this.refs.cmnt.value);

      debugger;  
      fetch('https://api.gould-pt.com/api/Email/SendEmail' ,
        {  
            method: 'POST',
            mode:'no-cors',
            headers: {
            
            
            'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
            
            
          },
          body:qs.stringify({

            therapist_name :this.refs.therapist_name.value,
            patient_name :this.refs.patient_name.value,
            patient_sign :localStorage.getItem("sign"),
            pta_sign :localStorage.getItem("pt_sign"),
            DOV :this.refs.DOV.value,
            hha_name : this.refs.hha_name.value,
            SOV : this.refs.SOV.value,
            EOV : this.refs.EOV.value,
            coments : this.refs.cmnt.value
            
         }) 

       
        })
        .then(response => {
          return alert("Successfully Sent")
          
        })
        .catch(error => {
          return alert("Waiting for internet connection .please don't refresh or leave this page, your data will be lost")
          
        })  
       //alert("hello")
       
       localStorage.getItem("therapist");
       localStorage.getItem('patient');
       localStorage.getItem('SOV');
       localStorage.getItem('DOV');
       localStorage.getItem('EOV');
       localStorage.getItem('hha');
       localStorage.getItem('cmnts');
       localStorage.getItem("sign");
       localStorage.getItem("pt_sign");

       //this.resetData();

      // this.props.history.push('/home')
       console.log("therapist_name=="+localStorage.getItem("therapist"))
   // localStorage.clear();
  // window.location.reload(setTimeout(100000));

  }
 
    }
    
    render(){     
      console.log("signature="+this.state.patient_sign);
      
            
        return(              
          <div  >  
            
               {/********************************************* top navbar ******************************************/}
               <div class="wrapper">
                    <nav class="navbar navbar-expand-md navbar-dark">
                      
                      <div class="mx-auto justify-content-center" id="collapsingNavbar">
                          <ul class="navbar-nav">
                              <li class="">
                                  <a class="text-white navbar-brand mr-0 pl-2 " href="" >Route sheet</a>
                              </li>
                          </ul>
                      </div>
                  </nav>
                      </div>
                    <div className="container-fluid text-left maxw">
                      {/* <!-- Top navigation --> */}
                  <header className="py-3 background text-dark font-weight-bold">
                      <div className="col text-center" style={{fontSize: "18px"}}>
                        <img src="images/logo.png" alt="" className="float-left" />Gould Therapy, LLC</div>
                </header>
               {/********************************************** Form ***********************************************/} 
                    <div style={{marginTop: "60px"}}>
                    <label htmlFor="name">Therapist name</label> 
                      <div className="input-group mb-3">
                        <input type="text" className="form-control border-right-0" 
                           placeholder="Therapist Name" 
                           ref="therapist_name"
                            name 
                        />
                        <div className="input-group-append border-left-0">
                          <span className="input-group-text bg-white"><i className="fa fa-user-md" /></span>
                        </div>
                      </div>
                                     
                      <label htmlFor="name">Patient's name</label>		
                        <div className="input-group mb-3">
                          <input type="text" className="form-control border-right-0" 
                            placeholder="Patient Name" 
                            name 
                            ref="patient_name"
                          />
                          <div className="input-group-append border-left-0">
                            <span className="input-group-text bg-white"><i className="fa fa-wheelchair" /></span>
                          </div>
                        </div>

                        <label htmlFor="name">Date of visit</label>
                        <div className="input-group mb-3">
                          <input type="date" className="form-control border-right-0" 
                            id="datepicker" 
                            ref="DOV"
                            placeholder="Date of Visit" 
                            name 
                            style={{display: "flow-root"}}
                            
                            />
                          
                          <div className="input-group-append border-left-0">
                            <span className="input-group-text bg-white"><i className="fa fa-calendar" /></span>
                          </div>
                        </div>

                        <label htmlFor="name">Start of visit</label>
                        <div className="input-group mb-3">
                          <input type="time" className="form-control border-right-0" 
                            placeholder="Start Of Visit" 
                            name 
                            ref="SOV"
                            style={{display: "flow-root"}}
                          />
                          <div className="input-group-append border-left-0">
                            <span className="input-group-text bg-white"><i className="fa fa-clock-o" /></span>
                          </div>
                        </div>

                        <label htmlFor="name">End of visit</label>
                          <div className="input-group mb-3">
                            <input type="time" className="form-control border-right-0" 
                              placeholder="End of Visit"
                              name
                              ref="EOV" 
                              style={{display: "flow-root"}}
                            />
                            <div className="input-group-append border-left-0">
                              <span className="input-group-text bg-white"><i className="fa fa-clock-o" /></span>
                            </div>
                          </div>

                        <label htmlFor="name">Patient's signature</label>
                        <Signcanvas
                          ref="signcanvas"
                             onChange = {this.state.patient_sign}
                            value = {this.state.patient_sign}
                         />

                        <label htmlFor="name">HHA name</label>
                                <div className="input-group mb-3">
                                  <input type="text" className="form-control border-right-0" 
                                    placeholder="HHA name" 
                                    name
                                    ref="hha_name" 
                                  />
                                  <div className="input-group-append border-left-0">
                                    <span className="input-group-text bg-white"><i className="fa fa-user" /></span>
                                  </div>
                                </div>

                        <label htmlFor="name">PT or PTA signature</label>
                        <SignatureCanvs 
                           onChange = {this.state.pta_sign}
                           value = {this.state.pta_sign}
                          />
                        
                        <label htmlFor="name">Comments if necessary</label>
                        <div className="input-container mb-3">
                          <textarea className="input-field custum" rows={3} defaultValue={""} ref="cmnt" />
                          <i className="icon fa fa-comment-o" />
                        </div>
                      
                      <div className="row text-center my-3">
                        <div className="col text-right">
                         <a href="#"> <input type="submit" onClick={this.handleSubmit.bind(this)} className="btn btn-info" name="Submit"></input></a>
                        </div>
                        <div className="col text-left">
                          <button type="submit" onClick={this.resetData.bind(this)}  className="btn btn-danger">Clear</button>
                        </div>
                      </div>
                      
                </div>
              </div>  
          </div>
        )
      }
    }
      
export default RouteComponent;